import React, { Suspense, lazy, useState, useEffect } from "react";
import "./App.scss";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import $ from "jquery";
import "react-loading-skeleton/dist/skeleton.css";
import "react-lazy-load-image-component/src/effects/blur.css";

// Lazy load all routes
const Profile = lazy(() =>
  import("./Pages/Templates_/TemplateThree/TemplateThree")
);
const NotFound = lazy(() => import("./Pages/NotFound/NotFound"));
const Integrations = lazy(() => import("./Pages/Integrations/Integrations"));
const Calendar = lazy(() => import("./Pages/Calendar/Calendar"));
const Account = lazy(() => import("./Pages/Account/Account"));
const ClientLogs = lazy(() => import("./Pages/ClientLogsPage/ClientLogs"));
const Help = lazy(() => import("./Pages/Help/Help"));
const Referral = lazy(() => import("./Pages/Referral/Referral"));
const Feedback = lazy(() => import("./Pages/Feedback/Feedback"));
const Communication = lazy(() => import("./Pages/Communication/Communication"));
const ForgotPassword = lazy(() =>
  import("./Pages/ForgotPasswordPage/ForgotPassword")
);
const UserInfo = lazy(() => import("./Pages/SuperAdmin/UserInfo"));
const Booking = lazy(() => import("./Pages/Booking/Booking"));
const ConfirmBooking = lazy(() => import("./Pages/Booking/ConfirmBooking"));
const ContactInfo = lazy(() => import("./Pages/SuperAdmin/ContactInfo"));
const Campaign = lazy(() => import("./Pages/SuperAdmin/Campaign"));
const ResetPassword = lazy(() =>
  import("./Pages/ResetPasswordPage/ResetPassword")
);
const ResetPasswordDash = lazy(() =>
  import("./Pages/ResetPasswordPage/Dashboard/ResetPasswordDash")
);
const OTP = lazy(() => import("./Pages/OtpPage/OTP"));
const PricingCheckout = lazy(() =>
  import("./Pages/LoginAndSignUP/PricingCheckout/Payment")
);
const DomainSelection = lazy(() =>
  import("./Pages/LoginAndSignUP/DomainSelection/DomainSelection")
);
const PricingCard = lazy(() =>
  import("./Pages/LoginAndSignUP/PricingV3/PricingV3")
);

const Login = lazy(() => import("./Pages/LoginAndSignUP/Login/Login"));
const SignupPage = lazy(() => import("./Pages/LoginAndSignUP/SignUp/SignUp"));
const CMS = lazy(() => import("./Pages/CMS/Gallery"));
const TemplateThree = lazy(() =>
  import("./Pages/TemplatesEdit/TemplateThree/TemplateThree")
);
const PaymentStatus = lazy(() => import("./Pages/PaymentStatus/PaymentStatus"));
const Admin = lazy(() => import("./Pages/Admin/Admin"));
const People = lazy(() => import("./Pages/People/People"));
// const Test = lazy(() => import("./Pages/Test/Test"));
const LayoutDashboard = lazy(() =>
  import("./Components/LayoutDashboard/LayoutDashboard")
);

function App() {
  const navigate = useNavigate();
  const [routes, setRoutes] = useState([]);
  const loggedIn = localStorage.getItem("jwt") !== null;
  axios.defaults.baseURL = "https://leaponapi.herokuapp.com";
  // axios.defaults.baseURL = "https://leaponapi-test.herokuapp.com";
  const username = window.location.pathname.split("/")[1];
  const pathname = window.location.pathname.split("/")[2];
  const meetDuration = window.location.pathname.split("/")[3];

  useEffect(() => {
    handleRoutes();
    isTokenValid();
    getCustomUsername();
    $("#preloader").css("display", "none");
  }, []);

  // useEffect(() => {
  //   const isAdminClient = false;
  //   if (isLoggedIn) {
  //     getClientLog(isAdminClient);
  //   }
  // }, [getClientLog]);

  const getCustomUsername = async () => {
    try {
      // const { data } = await axios.post("/api/advisor/get_custom_username/", {
      const { data } = await axios.post(
        "/api/user_profile/get_custom_username/",
        {
          custom_username: username,
        }
      );
      const { username: customUsername } = data;
      const basePath = `/${customUsername}`;
      if (pathname === "booking") {
        setRoutes((current) => [
          ...current,
          {
            element: <Booking isExternal={true} />,
            path: `${basePath}/booking`,
          },
        ]);
        if ([15, 30, 45, 60].includes(Number(meetDuration))) {
          setRoutes((current) => [
            ...current,
            {
              element: <Booking isExternal={true} />,
              path: `${basePath}/booking/${meetDuration}`,
            },
          ]);
          navigate(`${basePath}/booking/${meetDuration}`);
        } else {
          navigate(`${basePath}/booking`);
        }
      } else {
        setRoutes((current) => [
          ...current,
          { element: <Profile />, path: basePath },
        ]);
        navigate(basePath);
      }
    } catch (error) {
      console.error("Error fetching custom username:", error);
    }
  };

  const isTokenValid = async () => {
    if (loggedIn) {
      try {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
        };
        await axios.post("api/user/is_token_valid/", {}, config);
        console.log("Token Valid");
      } catch (error) {
        logout();
      }
    }
  };

  const logout = async () => {
    try {
      await axios.post("api/user/logout/");
      localStorage.removeItem("user_info");
      localStorage.removeItem("jwt");
      window.location.reload();
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const handleRoutes = () => {
    const commonRoutes = [
      { element: <SignupPage />, path: "/signup" },
      { element: <PricingCard />, path: "/signup/pricing" },
      { element: <DomainSelection />, path: "/signup/domain" },
      { element: <PricingCheckout />, path: "/signup/checkout" },
      { element: <SignupPage />, path: "/signup/:signup_url" },
      { element: <ForgotPassword />, path: "/forgot-password" },
      { element: <ResetPassword />, path: "/reset-password" },
      { element: <ConfirmBooking />, path: "/confirm-booking" },
      { element: <OTP />, path: "/otp-password" },
      { element: <PaymentStatus />, path: "/payment-status/:slug" },
      { element: <CMS />, path: "/cms" },
      // { element: <Test />, path: "/test" },
      { element: <NotFound />, path: "*" },
    ];

    const loggedOutRoutes = [
      { element: <Login />, path: "/login" },
      { element: <Navigate to="/login" />, path: "/card" },
      { element: <Navigate to="/login" />, path: "/account" },
      { element: <Navigate to="/login" />, path: "/people" },
      { element: <Navigate to="/login" />, path: "/admin-dashboard" },
      { element: <Navigate to="/login" />, path: "/setting" },
      { element: <Navigate to="/login" />, path: "/integrations" },
      { element: <Navigate to="/login" />, path: "/referral" },
      { element: <Navigate to="/login" />, path: "/calendar" },
      { element: <Navigate to="/login" />, path: "/client-logs" },
      { element: <Navigate to="/login" />, path: "/admin-client-logs" },
      { element: <Navigate to="/login" />, path: "/communication" },
      { element: <Navigate to="/login" />, path: "/refer" },
      { element: <Navigate to="/login" />, path: "/campaign" },
      { element: <Navigate to="/login" />, path: "/contact-info" },
      { element: <Navigate to="/login" />, path: "/feedback" },
      { element: <Navigate to="/login" />, path: "/user-info" },
      { element: <Navigate to="/login" />, path: "/analytics" },
      { element: <Navigate to="/login" />, path: "/reset-password-dash" },
    ];
    const loggedInRoutes = [
      { element: <Navigate to="/card" />, path: "/login" },
    ];

    setRoutes([
      ...commonRoutes,
      ...(loggedIn ? loggedInRoutes : loggedOutRoutes),
    ]);
  };

  const DashboardRoutes = [
    { element: <TemplateThree />, path: "/card" },
    { element: <Account />, path: "/account" },
    { element: <Calendar />, path: "/calendar" },
    { element: <ClientLogs />, path: "/client-logs" },
    { element: <Referral />, path: "/referral" },
    { element: <Communication />, path: "/communication" },
    { element: <Help />, path: "/help" },
    { element: <Feedback />, path: "/feedback" },
    { element: <Admin />, path: "/admin-dashboard" },
    { element: <ClientLogs />, path: "/admin-client-logs" },
    { element: <Integrations />, path: "/integrations" },
    { element: <UserInfo />, path: "/user-info" },
    { element: <ContactInfo />, path: "/contact-info" },
    { element: <Campaign />, path: "/campaign" },
    { element: <People />, path: "/people" },
    { element: <ResetPasswordDash />, path: "/reset-password-dash" },
  ];

  return (
    <Suspense fallback={<div id="preloader"></div>}>
      <Routes>
        {routes.map((route) => (
          <Route
            key={route.path.replace("/", "")}
            path={route.path}
            element={route.element}
          />
        ))}
        {loggedIn && (
          <Route path="/" element={<LayoutDashboard />}>
            {DashboardRoutes.map((route) => (
              <Route
                key={route.path.replace("/", "")}
                path={route.path}
                element={route.element}
              />
            ))}
          </Route>
        )}
      </Routes>
    </Suspense>
  );
}

export default App;
